import React, {useState, useEffect} from 'react'
import ManagePro from "../components/ManageProfile/managepro";
import Layout from '../components/layout'
import Login from '../pages/login';


const ManageAccount = ({ location }) =>{
    const [agreed, setAgreed] = useState(false)

    // This runs when the page is loaded.
    useEffect(() => {
      if (localStorage.getItem('isAuthenticated')) {
        setAgreed(true)
      }
    }, [])
  
    return(
 
        <Layout location={location}>
             {!agreed ? (
               <>
               <Login loginModal={agreed} />
               <div class="card">
                 <div class="center">
               <p style={{paddingTop:"50px",paddingBottom:"50px",fontSize:"25px"}}>Only members can view this page</p>
               </div>
               </div>
               </>
              ) : (
               <div className="box ">
                  <ManagePro />
               </div>
             )}
           </Layout>
       )
}

export default ManageAccount 