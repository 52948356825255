import React from "react";

import './manage.css'
import {
    MDBBtn,
    MDBIcon,
  } from 'mdbreact'
import { AXIOSLINK } from "../../utils/constants";
const axios = require('axios')
import Swal from 'sweetalert2'
import CryptoJs from 'crypto-js'
import { useStaticQuery, graphql } from "gatsby"


const ManagePro = () =>{
  const data = useStaticQuery(graphql`
  {
    allStrapiManageAccounts {
      nodes {
        ManageDynamicZone {
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
          }
      }
    }
  }
`)

    var updateInfo = data.allStrapiManageAccounts.nodes
    const ManageDynamicZone = updateInfo[0].ManageDynamicZone[0]

    const initialFormData = Object.freeze({
        emailAddress: '',
        MApassword:'',
    })

    const [formData, updateFormData] = React.useState(initialFormData)
    const handleChange = e => {
        updateFormData({
        ...formData,

        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
        })
    }

    function Checkuser(){
          const pwd = CryptoJs.MD5(formData.MApassword).toString()
          const data = {
          identifier : formData.emailAddress,
          //password : formData.MApassword,
          password : pwd,
          }
          
          axios.post(AXIOSLINK + 'auth/local', data)
          .then(function(result){
            if(result.data.user.email == data.identifier && result.status == 200){
                const UserInfo = {
                    id: result.data.user.id,
                    username : result.data.user.username,
                    firstName: result.data.user.FirstName,
                    lastName: result.data.user.LastName,
                    email: result.data.user.email,
                    address: result.data.user.Address,
                    city: result.data.user.City,
                    state: result.data.user.State,
                    zipcode: result.data.user.Zipcode,
                    licenseNumber: result.data.user.IOWALicenseNumber,
                    interpreterCredentials: result.data.user.InterepeterCredentials,
                    phoneNumber: result.data.user.UserPhoneNumber,
                    opt: result.data.user.Opt,
                    InterpreterSearchAddress:result.data.user.InterpreterSearchAddress,
                    InterpreterSearchCity:result.data.user.InterpreterSearchCity,
                    InterpreterSearchState:result.data.user.InterpreterSearchState,
                    InterpreterSearchZipcode:result.data.user.InterpreterSearchZipcode,
                    InterpreterSearchEmail:result.data.user.InterpreterSearchEmail,
                    InterpreterSearchPhoneNumber:result.data.user.InterpreterSearchPhoneNumber,
                    InterpreterSearchBusinessNumber:result.data.user.InterpreterSearchBusinessNumber,
                    InterpreterSearchVideoNumber:result.data.user.InterpreterSearchVideoNumber,
                  }  
                  sessionStorage.setItem('UserInfo',JSON.stringify(UserInfo))
                  sessionStorage.setItem('updateuserprofile', 'true')
                  window.location.pathname = '/UpdateProfile'            
            }
          
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Invalid Credentials!',
              confirmButtonColor: '#00c851',
            })
          })

    }

    return(
        <div>
          <div class="card manage_card" style={{
          backgroundColor:"#f9f9ff",
        }}>
               {/* ************************* */}
            <div>
            <h2
              class="membershipTitle"
              style={{
                fontFamily: ManageDynamicZone.TitleFontFamily,
                fontSize: ManageDynamicZone.TitleFontSize,
                fontWeight: ManageDynamicZone.TitleFontWeight,
                textAlign: ManageDynamicZone.TitleAlignment,
                color: ManageDynamicZone.TitleFontColor,
              }}
            >
              {ManageDynamicZone.TitleName}
            </h2>
            <br/>
            <div class="form-outline">
              <label htmlFor="email">
                {' '}
                <b>Email Address :</b>
                
              </label>
              <br />
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                class="form-control"
                name="emailAddress"
                required
                onChange={handleChange}
              />
            </div>
            <br />
            <div class="form-outline">
              <label htmlFor="psw">
                {' '}
                <b>Password :</b>
              </label>
              <input
                type="password"
                class="form-control"
                placeholder="Enter your password"
                class="form-control"
                name="MApassword"
                required
                onChange={handleChange}
              />
            </div>
            <p>**For the security purpose please enter your email and password to manage your account.**</p>
             {/* ************************* */}
             <div className="text-center mt-4">
             {/* <MDBBtn color="success"  outline type="submit"  onClick={Checkuser} >
                 Update Profile
              </MDBBtn> */}
              <button type="submit" class="btn btn-success managebtn" onClick={Checkuser}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"32%",}}>Update Profile</button>
              {/* <button type="button" class="button_green" onClick={Checkuser} >UPDATE PROFILE</button> */}
              </div>
              </div>
          </div>
        </div>
    )
}

export default ManagePro